import React from "react"

export const SmartLaundryInstall = () => {
  return (
    <section className="smartLaundryInstall">
      <h3 className="smartLaundryInstall__title e">Install</h3>
      <p className="smartLaundryInstall__smallTitle fw-b">インストール</p>

      <p className="smartLaundryInstall__text">
        下記QRコードから、アプリ（無料）をインストールし、
        <br />
        お客様情報を登録してください。
      </p>

      <div className="smartLaundryInstall__qrList">
        <div className="smartLaundryInstall__qrItem">
          <div className="smartLaundryInstall__imgWrap">
            <img
              className="smartLaundryInstall__qrImg"
              src="https://balukoweb.blob.core.windows.net/images/smartlaundry/is_googleplay.png"
              loading="eager"
              alt="QrCode"
            />
          </div>
          <div className="smartLaundryInstall__imgWrap">
            <a href="https://play.google.com/store/apps/details?id=jp.smart_laundry&hl=ja&gl=US">
              <img
                className="smartLaundryInstall__linkIcon"
                src="https://balukoweb.blob.core.windows.net/images/smartlaundry/btngoogleplay_bdg.png"
                loading="eager"
                alt="googlePlay"
              />
            </a>
          </div>
        </div>
        <div className="smartLaundryInstall__qrItem">
          <div className="smartLaundryInstall__imgWrap">
            <img
              className="smartLaundryInstall__qrImg"
              src="https://balukoweb.blob.core.windows.net/images/smartlaundry/is_apple.png"
              loading="eager"
              alt="QrCode"
            />
          </div>
          <div className="smartLaundryInstall__imgWrap">
            <a href="https://apps.apple.com/jp/app/smart-laundry/id1313142194">
              <img
                className="smartLaundryInstall__linkIcon"
                src="https://balukoweb.blob.core.windows.net/images/smartlaundry/btn_apple_bdg.png"
                loading="eager"
                alt="googlePlay"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
