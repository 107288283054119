import React from "react"

export const SmartLaundryAbout = ({ stores }) => {
  console.log(stores)
  const serviceItems = [
    {
      title: "空き状況確認",
      detail: "アプリ上でランドリー機器の空き状況が確認できます。",
      src: "https://balukoweb.blob.core.windows.net/images/smartlaundry/sl_pict01.svg",
    },
    {
      title: "ドアロック機能",
      detail:
        "アプリ操作で、利用中の洗濯乾燥機の乾燥中にドアにロックをかけられます。",
      src: "https://balukoweb.blob.core.windows.net/images/smartlaundry/sl_pict02.svg",
    },
    {
      title: "窓ブラインド機能",
      detail:
        "アプリ操作で、利用中の洗濯乾燥機の窓を曇りガラスに切り替えることができます。",
      src: "https://balukoweb.blob.core.windows.net/images/smartlaundry/sl_pict03.svg",
    },
    {
      title: "運転終了通知",
      detail: "洗濯や乾燥終了時、アプリに終了通知が届きます。",
      src: "https://balukoweb.blob.core.windows.net/images/smartlaundry/sl_pict04.svg",
    },
    {
      title: "キャッシュレス決済",
      detail:
        "クレジットカード、携帯キャリア、QRコード決済がご利用いただけます。",
      src: "https://balukoweb.blob.core.windows.net/images/smartlaundry/sl_pict05.svg",
    },
    {
      title: "領収書発行",
      detail: "アプリ内でデジタル領収書を発行できます。",
      src: "https://balukoweb.blob.core.windows.net/images/smartlaundry/sl_pict06.svg",
    },
    {
      title: "お知らせ機能",
      detail: "お得なセールやキャンペーン情報が届きます。",
      src: "https://balukoweb.blob.core.windows.net/images/smartlaundry/sl_pict07.svg",
    },
    {
      title: "ステイタス機能",
      detail:
        "アプリ内で貯めるスマートメダル獲得数に応じてお得なクーポンをお受け取りいただけます。",
      src: "https://balukoweb.blob.core.windows.net/images/smartlaundry/sl_pict08.svg",
    },
  ]
  return (
    <section className="smartLaundryAbout">
      <div className="smartLaundryAbout__titleContents">
        <img
          className="smartLaundryAbout__titleIcon"
          src="https://balukoweb.blob.core.windows.net/images/smartlaundry/sl01.png"
          loading="eager"
          alt="SmartLaundry"
        />
        <h2 className="smartLaundryAbout__title">スマートランドリーアプリ</h2>
        <p className="smartLaundryAbout__detailText">
          ※一部の店舗、機器でご利用いただけます。
        </p>
      </div>

      <div className="smartLaundryAbout__item">
        <h3 className="smartLaundryAbout__itemTitle e">Service</h3>
        <p className="smartLaundryAbout__itemSmallTitle fw-b">
          アプリからご利用いただけるサービス
        </p>

        <ul className="smartLaundryAbout__serviceItems">
          {serviceItems.map((item, index) => {
            return (
              <li className="smartLaundryAbout__serviceItem" key={index}>
                <div className="smartLaundryAbout__serviceImgWrap">
                  <img
                    className="smartLaundryAbout__serviceImg"
                    src={item.src}
                    loading="eager"
                    alt={`serviceIcon${index + 1}`}
                  />
                </div>

                <h4 className="smartLaundryAbout__serviceItemTitle fw-b">
                  {item.title}
                </h4>
                <p className="smartLaundryAbout__serviceItemDetail">
                  {item.detail}
                </p>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="smartLaundryAbout__item">
        <h3 className="smartLaundryAbout__itemTitle e">Status</h3>
        <p className="smartLaundryAbout__itemSmallTitle fw-b">
          ステイタス機能について
        </p>

        <div className="smartLaundryAbout__leadWrap">
          <p className="smartLaundryAbout__lead fw-b">
            アプリでランドリーを利用すると、アプリ上で仮想メダル「スマートメダル」を
            <br className="pc" />
            獲得できます。1年間（1月～12月）に獲得されたスマートメダル枚数に応じて、
            <br className="pc" />
            翌年度のスマートランドリーメンバーのステイタスが決まりステイタスごとの
            <br className="pc" />
            サービスや特典をご利用いただけます。
          </p>
          <p className="smartLaundryAbout__note">
            ※スマートメダル獲得枚数が各ステイタスアップ枚数に到達した時点で次のステイタスにアップいたします。
            <br className="pc" />
            また1月～12月に獲得したスマートメダル枚数に応じて翌年スタート時のステイタスが決定いたします。
          </p>
        </div>
        <div className="smartLaundryAbout__statusImgWrap">
          <img
            className="smartLaundryAbout__statusImg"
            src="https://balukoweb.blob.core.windows.net/images/smartlaundry/sl02.png"
            loading="eager"
            alt="SmartLaundryStatus"
          />
        </div>
      </div>

      <div className="smartLaundryAbout__item">
        <h3 className="smartLaundryAbout__itemTitle e">Store</h3>
        <p className="smartLaundryAbout__itemSmallTitle fw-b">
          ご利用いただける店舗
        </p>
        <ul className="smartLaundryAbout__storeList">
          {stores.map((store, index) => {
            return (
              <li className="smartLaundryAbout__store" key={index}>
                <a
                  href={`/${store.node.page_slug}`}
                  className="smartLaundryAbout__storePageLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  BLP{store.node.display_name}
                </a>
                <span>／</span>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}
