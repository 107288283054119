import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import { Fv } from "components/fv"
import { SmartLaundryAbout } from "components/self-laundry/smartlaundry/smartLaundryAbout"
import { SmartLaundryInstall } from "components/self-laundry/smartlaundry/smartLaundryInstall"

const SmartLaundry = ({ data }) => {
  const smartLaundryStores = data.allStrapiStore.edges
  return (
    <Layout>
      <div className="smartLaundry">
        <Fv
          pcFv="https://balukoweb.blob.core.windows.net/images/smartlaundry/sl-page-mv.jpg"
          spFv="https://balukoweb.blob.core.windows.net/images/smartlaundry/sl-page-mv-sp.jpg"
          fvTitle="Smart Laundry"
          color="white"
        />
      </div>
      <SmartLaundryAbout stores={smartLaundryStores} />
      <SmartLaundryInstall />
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="Smart Laundry"
    description="Smart Laundry（スマートランドリー）はキャッシュレス決済でランドリーを利用できるアプリです。ポイントが溜まり、お得に利用できるステータス機能のほか、アプリ限定のクーポン、ドアロック機能などをご利用いただけます。Baluko Laundry Placeでスマートランドリーアプリが使える店舗はこちらです。"
  />
)
export default SmartLaundry

export const query = graphql`
  query {
    allStrapiStore(
      filter: {is_smart_laundry: {eq: true}}
      sort: {order: ASC, fields: opening_date}
    ) {
      edges {
        node {
          display_name
          page_slug
        }
      }
    }
  }
`